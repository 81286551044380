<template>
  <div class="vx-row mb-12">
    <div class="vx-row mb-12" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Sales Order</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="salesOrder" disabled />
      </div>
    </div>
    <div class="vx-row mb-12" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <!-- <vs-button
          v-if="!edit"
          color="warning"
          v-on:click="editData"
          icon-pack="feather"
          icon="icon-edit"
        >Edit</vs-button>
        <vs-button
          v-else
          color="success"
          v-on:click="save"
          icon-pack="feather"
          icon="icon-save"
        >Save</vs-button> -->
      </div>
    </div>

    <div class="vx-row mb-6" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span
              class="mr-2"
            >{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item :key="index" v-for="(item,index) in limits" @click="changeLimit(item)">
              <span>{{item}}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div class="mx-auto"></div>
      <div class="vx-col sm:w-1/3 w-full">
        <div class="flex flex-wrap items-center justify-between">
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="search" placeholder="Search..." />
        </div>
      </div>
    </div>

    <!-- TABLE ACTION ROW -->
    <vs-table :data="data" stripe style="width:100%">
      <template slot="thead">
        <vs-th>Name</vs-th>
        <vs-th style="width:30%">Qty</vs-th>
        <vs-th style="width:30%">Qty Adjust</vs-th>
        <!-- <vs-th>Company (Teritory)</vs-th> -->
        <!-- <vs-th>Nro</vs-th> -->
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
           <vs-td style="align-item-center">{{tr.ItemName}}({{tr.ItemCode}})</vs-td>
          <vs-td>
            <vs-tr :key="indexsub" v-for="(subtr,indexsub) in tr.Request.split(',')">
                <vs-td>{{subtr}}</vs-td>
            </vs-tr>
          </vs-td>
          <vs-td>
            <vs-tr :key="indexsub" v-for="(subtr,indexsub) in tr.Quantity.split(',')">
            <template v-if="!edit">
                  <vs-td>{{subtr}} ({{tr.ItemUnit.split(',')[indexsub]}})</vs-td>
            </template>
            <template v-else>
                <vs-input-number
                  min="0"
                  :max="subtr"
                  :step="1"
                  v-model="salesOrderEdit[indextr][indexsub][1]"
                />{{tr.ItemUnit.split(',')[indexsub]}}
            </template>
            </vs-tr>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- PAGINATE -->
    <vs-pagination
      style="padding-top:5px"
      :total="maxPage"
      v-model="currentPageDetail"
      :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
      :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
    />
  </div>
</template>
<script>
export default {
  props: ["dataId", "salesOrder", "edit"],

  created() {
    console.log("created");
  },
  mounted() {
    this.getData(1);
  },
  watch: {
    dataId() {
      this.getData(1);
      // console.log('The dataId has changed!')
    }
  },
  computed: {
    createData: {
      set(val) {
        this.getData(1);
        console.log(val)
      }
    },
    currentPageDetail: {
      get() {
        return 1;
      },
      set(val) {
        this.pageDetail(val);
      }
    }
  },
  data: () => ({
    salesOrderEdit: [],
    limits: [5, 10, 25, 50],
    limitShow: 100,
    drawData: 0,
    isActive: 1,
    checked: [],
    modelCheck: [],
    checkedAll: false,
    search: "",
    detailShow: "core vx-col md:w-1/2 w-full mb-base",
    detailHide: "core vx-col md:w-1/1 w-full mb-base",
    detail: false,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,
    data: [{ id: 1 }]
  }),
  methods: {
    editData() {
      var salesOrder = [];
      for (var i = 0; i < this.data.length; i++) {
        // console.log(i+ "yeah" +this.data[i].id)
        salesOrder.push([
          this.data[i].sales_order_line_adjust_allocation.id,
          this.data[i].sales_order_line_adjust_allocation.quantity
        ]);
      }
      this.salesOrderEdit = salesOrder;
      this.edit = true;
      // console.log("Asd");
    },
    save() {
      this.$vs.loading();
      this.$http
        .put("ae/update/bySO/" + this.dataId, {
          token: localStorage.getItem("token"),
          salesOrder: this.salesOrderEdit
        })
        .then(resp => {
          console.log(resp)
          this.getData();
          this.$vs.loading.close();
        });
      this.edit = false;
    },
    pageDetail(val) {
      this.getData(val);
      return val;
    },
    changeLimit(val) {
      this.limitShow = val;
      this.getData(this.isActive);
    },
    getData(val = 1) {
      this.isActive = val;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/sales-order/lines", {
          params: {
            adjust: "adjust",
            sales_order_id: this.dataId,
            length: this.limitShow,
            search: this.search,
            draw: this.drawData,
            start: this.limitShow * (val - 1)
          }
        })
        .then(resp => {
          this.drawData = this.drawData + 1;
          this.data = resp.data.salesOrderLine;
          this.recordsTotal = resp.data.recordsTotal;
          this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
          this.dataEnd = resp.data.salesOrderLine.length;
          this.$vs.loading.close();
          // }
        });
    }
  }
};
</script>