<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Approval">
      <div class="vx-row mb-6" style="width:50%">
        <!-- <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect class="selectExample" v-model="territory" :options="optionTerritory" :multiple="false" group-values="area" group-label="name" :allow-empty="false" :group-select="true" placeholder="Type to search" track-by="value" label="text">
                    </multiselect>
        </div>-->
      </div>
      <vs-tabs :color="colorx">
        <vs-tab @click="getDP" :colorx="warning" label="Approve">
          <div class="con-tab-ejemplo">
            <div class="vx-row w-full flex mb-4">
              <vx-card
                :key="index"
                class="divider-bottom"
                v-for="(deliveryPlan, index) in deliveryPlans"
              >
                <div class="flex mb-12">
                  <div class="md:w-1/2">
                    <vs-button
                      v-on:click="rejectDelivery(deliveryPlan.DeliveryPlanID)"
                      color="danger"
                      icon-pack="feather"
                    >Reject Delivery Plan</vs-button>
                  </div>

                  <div class="md:w-1/2">
                    <vs-button
                      v-on:click="approveDelivery(deliveryPlan.DeliveryPlanID)"
                      color="success"
                      icon-pack="feather"
                      icon="icon-inbox"
                    >Approve Delivery Plan</vs-button>
                  </div>

                  <div class="md:w-1/4"></div>
                </div>
                <div class="md:w-1/4">
                  <b>Delivery Plan Number</b>
                  : {{deliveryPlan.DeliveryPlanCode}}
                  <br />
                  <b>Warehouse</b>
                  : {{deliveryPlan.WarehouseName}}
                  <br />
                  <b>Company</b>
                  {{deliveryPlan.CompanyName}}
                </div>
                <vs-tabs>
                  <vs-tab @click="colorx = 'warning'" label="Sales Order">
                    <complete :delivery-plan="deliveryPlan.DeliveryPlanID"></complete>
                  </vs-tab>
                </vs-tabs>
              </vx-card>
              <vx-card v-if="deliveryPlans.length==0">Empty :)</vx-card>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import complete from "./complete/complete.vue";

export default {
  components: {
    complete,
  },
  watch: {
    deliveryPlans() {
      // console.log('The dataId has changed!')
    },
  },
  data: () => ({
    colorx: "danger",
    items: [],
    deliveryPlans: [],
  }),
  mounted() {
    this.getDP();
  },
  methods: {
    test() {},

    getDP() {
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/approve", {
          params: {
            status: 1,
          },
        })
        .then((resp) => {
          console.log(resp);
          // if (resp.data.status == 500) {
          // } else {
          console.log("reload ya");
          this.deliveryPlans = resp.data.deliveryPlan;
          if (resp.data.length != 0) {
            for (var i = 0; i <= resp.data.length; i++) {
              this.deliveryPlans[i].tab = true;
            }
          }
          this.$forceUpdate();
          this.$vs.loading.close();
          // }
        });
    },
    approveDelivery(deliveryPlanId) {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("DeliveryPlanID", deliveryPlanId);
      bodyFormData.set("status", 2);
      this.$http
        .put(
          "api/v1/allocation-engine/list/delivery-plan/store/status",
          bodyFormData
        )
        .then((resp) => {
          if (resp.code == 200) {
            // } else {
            this.deliveryPlans = [];
            this.$nextTick(() => {
              this.getDP();
            });
          }
          this.$vs.loading.close();
        });
    },
    rejectDelivery(deliveryPlanId) {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("DeliveryPlanID", deliveryPlanId);
      bodyFormData.set("status", 0);
      this.$http
        .put(
          "api/v1/allocation-engine/list/delivery-plan/store/status",
          bodyFormData
        )
        .then((resp) => {
          if (resp.code == 200) {
            // } else {
            this.deliveryPlans = [];

            this.$nextTick(() => {
              this.getDP();
            });
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>